import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { HeroBlogProps } from 'libs/growth-platform-brand-system-v2/src/templates/HeroBlog';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { createSchema, morphism } from 'morphism';
import React from 'react';
import { schemaImage } from '../components/schemaImage';
import { schemaTag } from '../components/schemaTag';
import { schemaDatoButton } from '../schemaDatoButton';

export const schemaHeroBlog = createSchema<HeroBlogProps, any>({
  title: 'title',
  subtitle: 'subtitle',
  cta: {
    path: ['stickyBanner', 'locale', 'disableBannerCta', 'screenSize'],
    fn: ({ stickyBanner, locale, disableBannerCta, screenSize }: any) => {
      const bannerCta = stickyBanner?.banner?.banner?.[0]?.cta?.[0];
      if (!bannerCta || disableBannerCta) return;
      const cta = morphism(schemaDatoButton, {
        ...bannerCta,
        locale,
        allowedScreenSizes: ['lg'],
        screenSize,
        isTitleResponsive: true,
      });
      return (
        <ButtonTracking
          section="heroblogCta"
          index="0"
          variant="primary"
          color="default"
          component={ButtonV2}
          {...cta}
        />
      );
    },
  },
  ctaTitle: {
    path: ['stickyBanner', 'locale', 'disableBannerCta'],
    fn: ({ stickyBanner, disableBannerCta }: any) => {
      if (!stickyBanner || disableBannerCta) return;
      const title = stickyBanner?.banner?.banner?.[0]?.title;
      return title;
    },
  },
  backgroundColor: 'backgroundColor',
  authorImage: {
    path: 'author.image',
    fn: (propertieValue, source) => {
      if (propertieValue) {
        return morphism(schemaImage, propertieValue);
      }
      return;
    },
  },
  authorName: 'author.name',
  authorPosition: 'author.authorPosition',
  image: {
    path: 'image',
    fn: (propertieValue, source) => {
      if (propertieValue) {
        return morphism(schemaImage, propertieValue);
      }
      return;
    },
  },
  tags: {
    path: ['stickyBanner', 'locale', 'displayTags'],
    fn: ({ stickyBanner, displayTags }: any) => {
      if (!stickyBanner || !displayTags) return;
      const _tags = stickyBanner?.banner?.banner?.[0]?.tags;
      if (!_tags) return;
      const tags = _tags.map((x: any) =>
        morphism(schemaTag, { ...x, size: 'small' }),
      );
      return tags;
    },
  },
});
