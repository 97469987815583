import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { CardWithoutBgProps } from 'libs/growth-platform-brand-system-v2/src/layouts/CardWithoutBg';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { createSchema, morphism } from 'morphism';
import React from 'react';
import { schemaImage } from '../components/schemaImage';
import { schemaImageDisabledGatsby } from '../components/schemaImageDisabledGatsby';
import { schemaDatoButton } from '../schemaDatoButton';
import { TitleWithArrow } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2/TitleWithArrow';

export const schemaCardWithoutBg = createSchema<CardWithoutBgProps, any>({
  as: {
    path: ['as', 'Component'],
    fn: ({ as, Component }) => {
      if (as) return as;
      if (Component) return Component;
      return;
    },
  },
  title: 'title',
  subtitle: {
    path: ['subtitle', 'description'],
    fn: ({ subtitle, description }) => subtitle || description,
  },
  capsText: 'capsText',
  logo: {
    path: ['logo'],
    fn: ({ logo }) => {
      if (logo) return morphism(schemaImageDisabledGatsby, logo);
      return;
    },
  },
  tags: 'tags',
  image: {
    path: ['image'],
    fn: ({ image }) => {
      if (image) return morphism(schemaImage, image);
      return;
    },
  },
  imgPosition: 'imgPosition',
  ctaButton: {
    path: ['ctaButton', 'btnTitle', 'internalUrl', 'url', 'locale'],
    fn: ({ ctaButton, btnTitle, internalUrl, url, locale }) => {
      const _ctaButton = ctaButton?.[0] || ctaButton || {};
      let btn = {
        ..._ctaButton,
        variant: 'minimal',
        className: 'text-21',
        as: 'span',
      };

      if (_ctaButton?.title)
        btn = {
          ..._ctaButton,
          ...btn,
        };

      if (btnTitle && (internalUrl || url)) {
        btn = { ...btn, title: btnTitle, internalUrl, url };
      }

      if (_ctaButton?.title || (btnTitle && (internalUrl || url))) {
        btn = morphism(schemaDatoButton, { ...btn, locale });

        return (
          <ButtonTracking
            component={ButtonV2}
            {...{ ...btn, title: <TitleWithArrow title={btn.title} /> }}
            labelTracking={btn.title}
          />
        );
      }

      return;
    },
  },
  className: 'className',
  displaySubtitleOnSmallDevice: 'displaySubtitleOnSmallDevice',
  TitleTag: 'TitleTag',
  ContentTag: 'ContentTag',
  content: 'content',
});
